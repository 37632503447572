.card-promoted {
 width: 100%;
}
.card-promoted .card-img-top {
 position:relative;
}
.card-promoted .card-img-top>img {
 border-radius:21px;
 width: 100%;
}
.card-promoted .card-img-top>img.promoted-lock-overlay {
 position:absolute;
 top:0;
 left:0;
}
