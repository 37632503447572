#header.navbar{box-shadow:none;border-width:1px}

.results-bar-container{display:none}

.results-toggled .results-bar-container{display:block;background-color:#eee;width:100%;height:30px;line-height:30px;text-shadow:0 1px 0 #fff;margin:0 auto 15px;text-align:center;position:relative;top:0;left:0;z-index:1;overflow:hidden}
.results-toggled .results-bar-graphic{position:absolute;top:0;left:0;z-index:2;width:0px;height:30px;background-color:orange;border-left:0px solid green;border-right:0px solid red;opacity:.4}
.results-toggled .results-bar-text{z-index:3}


.results-bar-container.outcome-results{display:block;height:1em;line-height:1em;margin:0;background-color:transparent}
.results-bar-container.outcome-results:after{content:"\2219"}

.results-toggled .results-bar-container.outcome-results{width:20px;background-color:#eee}
.results-toggled .results-bar-container.outcome-results:after{content:""}

.results-bar-container.activity-results{width:160px;margin:0;height:20px;line-height:20px;font-size:12px}

.results-toggled .outcome-popout-toggle .outcome-name{margin-left:24px}

.results-toggled.results-loading .results-bar-container{background:repeating-linear-gradient(-45deg,rgba(255,255,255,0.75),rgba(255,255,255,0.75) 4px,rgba(0,0,0,.05) 4px,rgba(0,0,0,.05) 8px);color:#999}
.results-toggled.results-loading .results-bar-container.pod-tableofcontent-results:after{content:"Loading . . ."}
.results-toggled.results-loading .results-bar-container>.results-bar-text{display:none}
.results-toggled.results-loading .results-bar-container>.results-bar-graphic{display:none}

.results-toggled .results-na .results-bar-container.pod-tableofcontent-results:after{content:"For teachers & parents";color:#aaa;text-shadow:none}

.resources-view-panel-loading{-webkit-filter:grayscale(100%);filter:grayscale(100%);opacity:.5;z-index:1;position:relative;top:0;left:0}
.resources-view-panel-loadingoverlay{display:none}
.resources-view-panel-loading>.resources-view-panel-loadingoverlay{display:block;z-index:2;position:absolute;top:0;left:0;width:100%;height:500px;background:repeating-linear-gradient(-45deg,rgba(255,255,255,0.8),rgba(255,255,255,0.8) 15px,rgba(0,0,0,.1) 15px,rgba(0,0,0,.1) 30px)}

.resources img.lazyload{min-height:102px;min-width:160px}
.resources .list-unstyled.activities{padding-left:0}
.resources .list-unstyled.activities a.activity-name{font-size:13px}

/* Have issues with bootstrap container responsive settings being overwritten */
.pod-addtoeditor-bannerwidget .pod-list.affix {width:878px;top:64px}
@media (min-width: 1200px) {
 .container {width:1170px;max-width:none}
 .pod-addtoeditor-bannerwidget .pod-list.affix {width:1170px;top:68px}
}
@media only screen and (max-width: 900px) {
 .pod-addtoeditor-bannerwidget .pod-list.affix {width:90%;left:5%;top:68px}
}
.pod-view .row.green.fill{margin:0;background-color:#8cba46;color:#fff;padding:5px 0}
.pod-view .row.green.fill h2{color:#fff}

body .container.resources {
  font-size: 14px;
  line-height: 1.42857143;
}
.resources ul.activities {
  display:block;
}
body .container.resources .pod-tableofcontent h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.highlight{background-color:yellow}


.twitter-typeahead {
	width:100%;
	input {
		width:100%;
	}
}

/* need to remove this as we are not in the student section */
.sideBanner {
	display:none;
}

@media (max-width: 669px) {
.resources .outcome-contents .activity-results {
    display: block;
}
}